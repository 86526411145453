@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  @apply max-w-5xl m-auto pt-16 pb-16 text-gray-600 text-base;
}

#overlay {
  @apply fixed top-0 left-0 w-full h-full bg-white z-10 flex justify-center items-center text-gray-400 text-xl;
}

.title-container {
  @apply flex flex-row justify-between mb-6;
}

.button {
  @apply cursor-pointer bg-blue-500 text-white font-semibold px-4 py-2 rounded hover:bg-blue-400;
}

.button.disabled {
  @apply pointer-events-none opacity-50;
}

nav .button {
  @apply ml-2;
}

.input-field {
  @apply w-full border px-4 py-2;
}

.textarea-field {
  @apply w-full min-h-[80vh] font-mono p-4 border;
}

.page-title {
  @apply text-2xl mb-4;
}

.page-title a {
  @apply text-blue-500 hover:text-blue-400;
}

.page-title a.back {
  @apply mr-4;
}

#post-list > div {
  @apply p-4 mb-2 bg-gray-100 rounded flex justify-between items-center;
}

#post-list .title {
  @apply w-1/2 pr-4 font-semibold;
}

#post-list a {
  @apply hover:underline;
}

#post-list .date,
#post-list .tag {
  @apply w-1/5;
}

.fieldset label {
  @apply mb-2 font-semibold block;
}

.fieldset label span {
  @apply font-normal text-blue-500 cursor-pointer hover:text-blue-400;
}

.delete {
  @apply text-red-700 text-sm;
}

.draft-tag {
  @apply rounded bg-slate-500 text-white px-2 py-1 mr-1 text-sm;
}

.preview-tag {
  @apply rounded bg-green-500 text-white px-2 py-1 mr-1 text-sm;
}
